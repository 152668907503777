<template>
  <div class="home-activity-info">
    <div class="home-activity-info-banner">
      <div>
        <div class="home-activity-info-banner-title">
          <span class="home-activity-info-banner-title-title"
            >{{ activityDetail.activityName }}
          </span>
          <!-- <a-tag color="#17CEAD">{{
            activityDetail.activityMode | dict("ACTIVITY_MODE")
          }}</a-tag> -->
          <!-- <p>
            <a-icon type="clock-circle" theme="filled" />
            <span>报名时间：</span>
            {{ activityDetail.enrollStartTime | moment("YYYY-MM-DD HH:mm") }}
            ～ {{ activityDetail.enrollEndTime | moment("YYYY-MM-DD HH:mm") }}
          </p> -->
          <p>
            <a-icon type="clock-circle" theme="filled" />
            <span>活动时间：</span>
            {{ activityDetail.startTime | moment("YYYY-MM-DD HH:mm") }}
            ～ {{ activityDetail.endTime | moment("YYYY-MM-DD HH:mm") }}
          </p>
        </div>
        <div class="home-activity-info-banner-date" v-if="activityDetail.conferenceId && activityDetail.activityEnrollStatus != '3'">
          <h6>报名倒计时</h6>
          <div>
            <p>{{ day[0] }}</p>
            <p>{{ day[1] }}</p>
            <span>天</span>
            <p>{{ hour[0] }}</p>
            <p>{{ hour[1] }}</p>
            <span>时</span>
            <p>{{ min[0] }}</p>
            <p>{{ min[1] }}</p>
            <span>分</span>
          </div>
          <span><img v-if="qrCode" :src="qrCode" alt="小程序码" class="apply-code-img"></span>
          <p>微信扫码报名</p>
          <!-- <a-button
            :class="{ 'btn-red': activityDetail.processStatus != 3 }"
            size="large"
            @click="activityRegister"
            :disabled="activityDetail.processStatus == 3"
            >{{ activityDetail.isJoinIn ? "已报名" : "立即报名" }}</a-button
          > -->
          <!-- <p>
            已报名：<span>{{ activityDetail.enrollTotal }}</span>人
          </p> -->
        </div>
      </div>
    </div>
    <div class="home-activity-info-content-box">
      <div class="home-activity-info-head">
        <a-breadcrumb separator=">">
          <a-breadcrumb-item
            ><router-link to="/activity">培训活动</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>活动详情</a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <div class="home-activity-info-content">
        <div class="home-activity-info-content-text">
          <share :name="activityDetail.activityName" :val="activityDetail" />
          <div class="home-activity-info-content-desc">
            <span>基本信息</span>
            <div class="home-activity-info-list">
              <div v-if="activityDetail.activityTypeName"><span>活动分类：</span>{{ activityDetail.activityTypeName }}</div>
              <div><span>活动地点：</span>{{ activityDetail.location || '/'}}</div>
              <div v-if="activityDetail.isHost">
                <span>主办单位：</span>
                <p>
                  <template v-if="activityDetail.orgs && activityDetail.orgs.length">
                    <template v-for="el in activityDetail.orgs">
                      <template v-if="el.orgType == '1'">
                        <span :key="el.id">{{ el.orgName }}</span>
                      </template>
                    </template>
                  </template>
                </p>
              </div>
              <div v-if="activityDetail.isPhone">
                <span>手机号码：</span>
                <p>
                  <template v-if="activityDetail.contacts && activityDetail.contacts.length">
                    <template v-for="el in activityDetail.contacts">
                      <template v-if="el.contactType == '1'">
                        <span :key="el.id">{{ el.contactDetail }}</span>
                      </template>
                    </template>
                  </template>
                </p>
              </div>
              <div v-if="activityDetail.isPartner">
                <span>承办单位：</span>
                <p>
                  <template v-if="activityDetail.orgs && activityDetail.orgs.length">
                    <template v-for="el in activityDetail.orgs">
                      <template v-if="el.orgType == '2'">
                        <span :key="el.id">{{ el.orgName }}</span>
                      </template>
                    </template>
                  </template>
                </p>
              </div>
              <div v-if="activityDetail.isLandline">
                <span>座机：</span>
                <p>
                  <template v-if="activityDetail.contacts && activityDetail.contacts.length">
                    <template v-for="el in activityDetail.contacts">
                      <template v-if="el.contactType == '2'">
                        <span :key="el.id">{{ el.contactDetail }}</span>
                      </template>
                    </template>
                  </template>
                </p>
              </div>
              <div v-if="activityDetail.isAssist">
                <span>协办单位：</span>
                <p>
                  <template v-if="activityDetail.orgs && activityDetail.orgs.length">
                    <template v-for="el in activityDetail.orgs">
                      <template v-if="el.orgType == '3'">
                        <span :key="el.id">{{ el.orgName }}</span>
                      </template>
                    </template>
                  </template>
                </p>
              </div>
              <div v-if="activityDetail.isMail">
                <span>邮箱：</span>
                <p>
                  <template v-if="activityDetail.contacts && activityDetail.contacts.length">
                    <template v-for="el in activityDetail.contacts">
                      <template v-if="el.contactType == '3'">
                        <span :key="el.id">{{ el.contactDetail }}</span>
                      </template>
                    </template>
                  </template>
                </p>
              </div>
              <div v-if="activityDetail.isGuide">
                <span>指导单位：</span>
                <p>
                  <template v-if="activityDetail.orgs && activityDetail.orgs.length">
                    <template v-for="el in activityDetail.orgs">
                      <template v-if="el.orgType == '4'">
                        <span :key="el.id">{{ el.orgName }}</span>
                      </template>
                    </template>
                  </template>
                </p>
              </div>
              <div><span>报名时间：</span>{{ activityDetail.enrollStartTime | moment("YYYY-MM-DD") }} ～ {{ activityDetail.enrollEndTime | moment("YYYY-MM-DD") }}</div>
            </div>
          </div>
          <div class="home-activity-info-content-desc">
            <span>活动介绍</span>
            <div v-html="activityDetail.content"></div>
          </div>
          <div class="home-activity-info-content-desc" v-if="activityDetail.meetingInstruction">
            <span>参会须知</span>
            <div v-html="activityDetail.meetingInstruction"></div>
          </div>
        </div>
        <div class="home-activity-info-all">
          <!-- <div class="home-activity-app">
            <img :src="qrCode" alt="小程序码">
            <div>
              <p>微信扫码报名</p>
              <span>扫码查看此活动</span>
            </div>
          </div> -->
          <p>更多推荐</p>
          <div class="home-activity-info-all-list">
            <div
              v-for="item in activityList"
              :key="item.id"
              @click="activityInfoBtn(item.id)"
            >
              <v-img
                :attachment-id="item.activityCoverAttmId"
                :default-img="require('@/assets/img/kctp.png')"
              />
              <span>{{ item.activityName }}</span>
              <p>
                <a-icon type="clock-circle" />{{
                  item.enrollStartTime | moment("YYYY/MM/DD")
                }}
                - {{ item.enrollEndTime | moment("YYYY/MM/DD") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import share from "./shareActivity.vue";
import VImg from "@/components/VImg";
import {
  getActivityInfo,
  getActivityList,
  activitySignUp,
  activityQrCode
} from "@/api/activity";
import { mapGetters } from "vuex";
export default {
  components: {
    share,
    VImg,
  },
  data() {
    return {
      activityDetail: "",
      day: ["0", "0"],
      hour: ["0", "0"],
      min: ["0", "0"],
      endTime: "",
      activityList: [],
      qrCode:''
    };
  },
  computed: {
    ...mapGetters(["access_token"]),
    id: function () {
      return this.$route.query.id;
    },
  },
  mounted() {
    this.activityInfo();
    this.loadGetActivityList();
    this.loadactivityQrCode()
  },
  methods: {
    loadactivityQrCode(){
      activityQrCode(this.id).then((res)=>{
        this.qrCode = res.qrCodeBase64
      })
    },
    activityInfo() {
      getActivityInfo(this.id).then((res) => {
        let data = res
        if(data.contacts){
          for (let index = 0; index < data.contacts.length; index++) {
            if(data.contacts[index].contactType == 1){
              data.isPhone = true
            }else if(data.contacts[index].contactType == 2){
              data.isLandline = true
            }else if(data.contacts[index].contactType == 3){
              data.isMail = true
            }
          }
        }
        if(data.orgs){
          for (let index = 0; index < data.orgs.length; index++) {
            if(data.orgs[index].orgType == 1){
              data.isHost = true
            }else if(data.orgs[index].orgType == 2){
              data.isPartner = true
            }else if(data.orgs[index].orgType == 3){
              data.isAssist = true
            }else if(data.orgs[index].orgType == 4){
              data.isGuide = true
            }
          }
        }
        console.log(data)
        this.activityDetail = data;
        this.countTime();
      });
    },
    loadGetActivityList() {
      let page = {
        current: 1,
        size: 4,
      };
      getActivityList(page, {}).then((res) => {
        this.activityList = res.records;
      });
    },
    activityInfoBtn(id) {
      this.$router.push({
        path: "/activity/activityInfo",
        query: { id },
      });
      this.activityInfo();
    },
    // 报名
    activityRegister() {
      if (this.access_token) {
        if (this.activityDetail.isJoinIn) {
          return;
        }
        activitySignUp(this.activityDetail.id, {}).then((res) => {
          this.$message.success("报名成功");
          this.activityInfo();
        });
      } else {
        this.$message.warning("您还未登录");
      }
    },
    // 倒计时
    countTime() {
      // 获取当前时间
      let date = new Date();
      let now = date.getTime();
      // 设置截止时间
      let endDate = new Date(this.activityDetail.enrollEndTime);
      let end = endDate.getTime();
      // 时间差
      let leftTime = end - now;
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 天
        let d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        let isStringD = d;
        d = d < 10 ? "0" + d : d;
        if (isStringD > 10) {
          d = JSON.stringify(d);
        }
        if(typeof d != 'string'){
          d = `${d}`
        }
        this.day = d.split("");
        // 时
        let h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        let isStringH = h;
        h = h < 10 ? "0" + h : h;
        if (isStringH > 10) {
          h = JSON.stringify(h);
        }
        this.hour = h.split("");
        // 分
        let m = Math.floor((leftTime / 1000 / 60) % 60);
        let isStringM = m;
        m = m < 10 ? "0" + m : m;
        if (isStringM > 10) {
          m = JSON.stringify(m);
        }
        this.min = m.split("");
      } else {
        this.day = ["0", "0"];
        this.hour = ["0", "0"];
        this.min = ["0", "0"];
      }
      // 等于0的时候不调用
      if (
        Number(this.hour) === 0 &&
        Number(this.day) === 0 &&
        Number(this.min) === 0
      ) {
        return;
      } else {
        // 递归每秒调用countTime方法，显示动态时间效果,
        setTimeout(this.countTime, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.apply-code-img{
  width: 98px;
  height: 98px;
  display: block;
  margin: 0 auto 12px;
}
.home-activity-info {
  .home-activity-info-banner {
    height: 280px;
    background: url(../../assets/img/hdbanner2.png) no-repeat;
    background-size: 100% 100%;
    > div {
      width: 1280px;
      height: 280px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .home-activity-info-banner-title {
        width: 540px;
        .home-activity-info-banner-title-title {
          display: block;
          font-size: 32px;
          color: #262626;
          line-height: 46px;
          margin-bottom: 24px;
        }
        > p {
          display: flex;
          color: #595959;
          align-items: center;
          line-height: 16px;
          > i {
            color: #EA0B06;
            margin-right: 16px;
          }
        }
      }
      .home-activity-info-banner-date {
        > h6 {
          font-size: 16px;
          line-height: 15px;
          text-align: center;
          margin-bottom: 20px;
        }
        > div {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          > p {
            width: 36px;
            height: 44px;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
            border-radius: 2px;
            background: url(../../assets/img/datebg.png) no-repeat;
            background-size: 100% 100%;
            font-size: 36px;
            text-align: center;
            line-height: 40px;
            margin-right: 8px;
          }
          > span {
            margin: 0 16px 0 8px;
            font-size: 24px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        >span{
          width: 98px;
          height: 98px;
          display: block;
          margin: 0 auto;
        }
        > button {
          display: block;
          width: 160px;
          font-size: 16px;
          margin: 0 auto 12px;
        }
        > p {
          line-height: 14px;
          font-size: 14px;
          text-align: center;
          // display: flex;
          // justify-content: center;
          // color: #8c8c8c;
          // > span {
          //   color: #EA0B06;
          // }
        }
      }
    }
  }
  .home-activity-info-content-box {
    width: 1280px;
    margin: 0 auto;
    padding: 24px 0 56px;
    .home-activity-info-head {
      margin-bottom: 24px;
    }
    .home-activity-info-content {
      display: flex;
      align-items: flex-start;
      .home-activity-info-content-text {
        padding: 24px;
        width: 1000px;
        background: #fff;
        position: relative;
        .home-activity-info-content-desc {
          margin-bottom: 32px;
          &:last-child {
            margin-bottom: 0;
          }
          > span {
            line-height: 18px;
            font-size: 18px;
            font-weight: bold;
            display: block;
            margin-bottom: 24px;
          }
          > p {
            display: flex;
            line-height: 12px;
            color: #8c8c8c;
            margin-bottom: 16px;
            &:last-child {
              margin-bottom: 0;
            }
            > span {
              color: #262626;
            }
          }
          > div {
            line-height: 22px;
            text-align: justify;
            overflow: hidden;
          }
        }
      }
      .home-activity-info-all {
        width: 256px;
        margin-left: 24px;
        > p {
          background: #fff;
          height: 56px;
          padding: 0 16px;
          border-bottom: 1px solid #f0f0f0;
          line-height: 56px;
          font-size: 16px;
        }
        .home-activity-info-all-list {
          background: #fff;
          padding: 16px 16px 0;
          > div {
            cursor: pointer;
            margin-bottom: 24px;
            > img {
              display: block;
              width: 100%;
              height: 126px;
              margin-bottom: 16px;
            }
            > span {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              line-height: 14px;
              margin-bottom: 16px;
            }
            > p {
              display: flex;
              color: #8c8c8c;
              line-height: 14px;
              > i {
                margin-right: 8px;
              }
            }
            &:last-child{
              padding-bottom: 24px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
.home-activity-info-list{
  display: flex;
  flex-wrap: wrap;
  >div{
    width: 50%;
    padding-right: 12px;
    font-size: 14px;
    color: #262626;
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    >span{
      color: #8c8c8c;
      white-space: nowrap;
    }
    >p{
      flex: 1;
      >span{
        display: block;
      }
    }
  }
}
.home-activity-app{
  margin-bottom: 22px;
  background: #fff;
  padding: 12px;
  display: flex;
  align-items: center;
  >img{
    width: 98px;
    height: 98px;
    display: block;
    margin-right: 24px;
  }
  >div{
    flex: 1;
    >p{
      font-size: 16px;
      line-height: 16px;
      color: #262626;
      margin-bottom: 23px;
    }
    >span{
      display: block;
      font-size: 12px;
      color: #8c8c8c;
      line-height: 13px;
    }
  }
}
</style>
<style>
.home-activity-info-head .ant-breadcrumb a:hover {
  color: #EA0B06;
}
.home-activity-info-content-desc > div img {
  max-width: 100%;
}
.home-activity-info-banner-date .ant-btn[disabled] {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
</style>
