<template>
  <div class="activity-share">
    <div>
      <span @click="shareShow = !shareShow"><img alt="图片" src="@/assets/img/fx1.png"/></span>
      <div v-if="shareShow">
        <span @click="shareToQQ()"><img alt="图片" src="@/assets/img/QQ.png"/></span>
        <span @click="shareToMicroblog()"><img alt="图片" src="@/assets/img/weibo.png"/></span>
        <span @click="shareToKj()"><img alt="图片" src="@/assets/img/kongjian.png"/></span>
        <a-popover placement="right" @mouseenter="showCode()">
          <div class="wx-code-div" slot="content">
            <img :src="qrCode" alt="小程序码" class="apply-code-img" style="width: 100%; height: 100%">
          </div>
          <img alt="图片" class="wx-logo" type="primary" src="@/assets/img/weixin.png"/>
        </a-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { activityQrCode } from "@/api/activity";

let url = document.location; //获取当前网页地址
export default {
  props: ['name', 'val'],
  data() {
    return {
      shareShow: false,
      qrCode: ""
    }
  },
  mounted() {
    this.qrCode = ""
  },
  methods: {
    showCode() {
      if(!this.qrCode){
        activityQrCode(this.val.id).then((res)=>{
          this.qrCode = res.qrCodeBase64
        })
      }
    },
    shareToQQ() {
      var _shareUrl = "https://connect.qq.com/widget/shareqq/index.html?";
      _shareUrl += "url=" + encodeURIComponent(url);
      _shareUrl += "&sharesource=qzone";
      _shareUrl += "&title=" + encodeURIComponent(this.name);
      window.open(_shareUrl, "_blank");
    },
    shareToMicroblog() {
      var sharesinastring =
          "http://service.weibo.com/share/share.php?title=" +
          encodeURIComponent("「" + this.name + "」" + " 点这里" + url);
      window.open(sharesinastring, "_blank");
    },
    shareToKj() {
      window.open(`https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${encodeURIComponent(url)}&sharesource=qzone&title=${this.name}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.activity-share {
  position: absolute;
  top: 0;
  left: -64px;

  > span {
    display: flex;
    width: 40px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  > div {
    width: 40px;
    background: #FFFFFF;
    border-radius: 20px;
    margin-top: 16px;
    padding: 10px 0;
    transition: all .3s;

    > span {
      display: block;
      cursor: pointer;

      > img {
        display: block;
        margin: 0 auto;
      }
    }

    > div {
      > span {
        display: block;
        margin-top: 14px;
        cursor: pointer;

        > img {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
}

.wx-code-div {
  width: 120px;
  height: 120px;
  cursor: pointer;
  > apply-code-img{
    width: 118px;
    height: 118px;
    display: block;
    margin: 0 auto 12px;
  }
}

.wx-logo {
  display: block;
  margin: 0 auto;
  margin-top: 14px;
  cursor: pointer;
}
</style>
